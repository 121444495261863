<template>
  <div>
    <portal to="page-top-title">Category #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'subjects-categories-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/subjects/categories/${id}`" data-prop="category" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ category.name }}</column-info>
          <column-info title="Group" v-if="category.group">
            <router-link :to="{ name: 'categoryGroups-single', params: { id: category.group.id } }">{{ category.group.name }}</router-link>
          </column-info>
          <column-info title="Created At">{{ category.created_at }}</column-info>
          <column-info title="Updated At">{{ category.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Active">
            <badge-active :value="category.active"/>
          </column-info>
          <column-info title="Subjects">
            <badge title="Total">{{ category.subjects_count_total }}</badge>
            <badge title="Active" color="green">{{ category.subjects_count_active }}</badge>
            <badge title="Inactive" color="red">{{ category.subjects_count_inactive }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Subjects" url="/subjects" :url-query="{ category_id: id }" data-prop="subjects" loader>
      <template slot="box-tools-right">
        <btn :route="{ name: 'subjects-new', query: { category: id } }" size="xs" icon="fa-plus" text>Add New</btn>
      </template>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Value</th>
          <th class="text-center">Has Codes</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="subject in subjects" :key="`subjects-tr-${subject.id}`" :value="subject">
          <td>{{ subject.id }}</td>
          <td>{{ subject.name }}</td>
          <td>{{ subject.value | textBrief }}</td>
          <td class="text-center">
            <badge-active :value="!!subject.value_with_codes"/>
          </td>
          <td class="text-center">
            <badge-active :value="subject.active"/>
          </td>
          <td class="text-right">{{ subject.created_at }}</td>
          <td class="text-right">{{ subject.updated_at }}</td>
          <td class="text-right">
            <template v-if="subject.active">
              <btn icon="fa-ban" color="danger" @click.stop.native="confirmAction(subject.id, 'deactivate')">Deactivate</btn>
            </template>
            <template v-else>
              <btn icon="fa-check" color="success" @click.stop.native="confirmAction(subject.id, 'activate')">Activate</btn>
            </template>
            <btn :route="{ name: 'subjects-edit', params: { id: subject.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'subjects-single', params: { id: subject.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'
import textBrief from '@/modules/filters/textBrief'
import Action from '@/modules/utils/Action'

export default {
  name: 'SubjectsCategoriesSingle',
  metaInfo () {
    return { title: 'Subjects | Category #' + this.id }
  },
  components: {
    BoxSingle,
    BoxTable,
    Btn,
    Badge,
    BadgeActive
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      category: {},
      subjects: []
    }
  },
  filters: {
    textBrief
  },
  methods: {
    confirmAction (id, action) {
      Action.confirm({
        id: id,
        action: action,
        name: 'Subject',
        route: '/subjects/:id/action',
        collection: this.subjects
      })
    }
  }
}
</script>
